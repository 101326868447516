<template>
  <div>
    <app-header @getHeight="getHeight"></app-header>
    <div style="background-color: #fff">
      <div class="max_width min_width">
        <div class="flex align_center padtb_20" :style="{ 'margin-top': offsetHeight + 'px' }">
          <div class="f838 size_14">当前位置：</div>
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <!-- <el-breadcrumb-item class="d8f">政策法规</el-breadcrumb-item> -->
            <el-breadcrumb-item class="" style="color: #ff6618">对接状态</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
      </div>
    </div>
    <!-- <div style="background-color: #fff; padding: 30px 0px"> -->
    <div style="background-color: #fff;">
      <div class="max_width min_width">

        <div class="flex " style="flex-wrap:wrap ;    justify-content: space-between;">
          <div v-for="(item, index) in selectorder" :key="item.id" style="width: 710px;background: #F2F4FF;border-radius: 10px;padding: 30px;
             margin-top: 30px">
            <div class="size_24 bold" style="margin-bottom: 20px;">{{ item.demand_name }}</div>

            <div class="size_14 flex" style="margin-bottom: 20px;">
              <div style="width: 30px;
                    font-size: 20px;
                    height: 30px;color:#ffffff;line-height: 30px;text-align: center;background: #fb1010;">
                供</div>
              <div style="margin-left: 10px;" class="size_20">

                {{ item.enterprise_name.replace(/(.{3}).*(.{4})/, "$1********$2") }}
              </div>
              <div class="size_14 flex" style="margin-left: 30px;">

                <div class="need" style="">
                  需</div>
                <div style="margin-left: 10px;" class="size_20">

                  {{ item.response_name.replace(/(.{3}).*(.{4})/, "$1********$2") }}
                </div>

              </div>
            </div>

            <div class="size_20" style="height: 40px;color: #FF6417;line-height: 40px;;border-radius: 6px;">
              对接状态：<span v-if="item.response_status === 1">正在对接</span>
              <span v-else-if="item.response_status === 2">达成意向</span>
              <span v-else-if="item.response_status === 3">签订合同</span>

            </div>

          </div>

        </div>



      </div>
    </div>
    <div>
      <!-- 分页 -->
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page="paginations.page_index" :page-sizes="paginations.page_sizes" :page-size="paginations.page_size"
        :layout="paginations.layout" :total="paginations.total">
      </el-pagination>
    </div>
    <app-footer></app-footer>
  </div>
</template>
  
<script>
import Header from "@/components/Header/header.vue";
import Footer from "@/components/Footer/footer.vue";
export default {
  data() {
    return {
      offsetHeight: 0,
      keyword: "",
      num: "",
      allselectorder: [],
      selectorder: "",
      // releasetext: "",
      TableData: [],
      total: "",

      patterns: "", //服务模式
      paginations: {
        page_index: 1, // 当前位于哪页
        total: 10, // 总数
        page_size: 10, // 1页显示多少条
        page_sizes: [10, 20, 50, 90], //每页显示多少条
        layout: "total, sizes, prev, pager, next, jumper", // 翻页属性
      },
    };
  },

  components: {
    "app-header": Header,
    "app-footer": Footer,
  },
  created() {
    this.responselist();
    //   this.releasetext = this.pattern[0].name;
  },
  methods: {

    // 获取对接状态
    responselist(item) {
      let data = {
        token: localStorage.eleToken,
        page: item ? item : this.paginations.page_index,
        limit: this.paginations.page_size,
      };
      console.log(data, "data");
      this.$post("demand_response_index", data).then((res) => {
        console.log(res.result, "res.result");
        this.allselectorder = res.result;
        if (res.count === "") {
          this.paginations.total = 0;
        } else {
          console.log("111");
          this.paginations.total = res.count;
        }

        //设置默认的分页数据
        if (this.allselectorder.length != 0) {
          this.selectorder = this.allselectorder.filter((item, index) => {
            return index < this.paginations.page_size;
          });
        } else {
          this.selectorder = [];
        }
        console.log(this.allselectorder, "this.allselectorder");
      });
    },

    setTag(name, id = "") {
      this[name] = id;
      this.paginations.page_index = 1;
      console.log("789");
      this.policyList = [];
      this.responselist();
      //   this.selectlist();
    },





    getHeight(data) {
      console.log(data, "data");
      this.offsetHeight = data;
    },

    // 分页
    handleSizeChange(page_size) {
      console.log(page_size, "page_size");
      this.paginations.page_index = 1;
      this.paginations.page_size = page_size;

      this.responselist();

      this.selectorder = this.allselectorder.filter((item, index) => {
        return index < page_size;
      });
    },

    handleCurrentChange(page) {
      console.log(page, "handleCurrentChangepage");
      //获取当前页
      let index = this.paginations.page_size * (page - 1);
      //获取数据总数
      let nums = this.paginations.page_size * page;
      this.responselist(page);
      //容器
      let tables = [];
      for (let i = index; i < nums; i++) {
        if (this.allselectorder[i]) {
          tables.push(this.allselectorder[i]);
        }
        this.selectorder = tables;
      }
    },
  },
};
</script>
<style scoped>
.sear_search {
  border-radius: 6px;
  margin-left: 12px;
}

.el-input__inner {
  background-color: #f5f5f5 !important;
  border: none !important;
  border-radius: 25px !important;
}

.sear_search .el-icon-search {
  right: 10px;
  color: #ff7129 !important;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  top: 10px;
  position: relative;
}

.sear_search .el-input__clear {
  height: 100% !important;
}

.laws_tag {
  padding: 3px 12px;

  color: #ff7129;
  background: #ffffff;
  border-radius: 20px;
  font-size: 14px;
  cursor: pointer;
}

::v-deep.el-pagination {
  text-align: center;
  padding: 20px 0px;
  background-color: #fff;
}

.productbox {
  width: 272px;
  height: 294px;
  background: #ffffff;
  border: 1px solid #f2f2f2;
  margin: 0px 40px 40px 0px;
}

.productbox:hover {
  box-shadow: 0px 2px 8px 0px rgba(0, 132, 255, 0.15);
}

.servehove:hover {
  color: #ff7129;
}


.need {
  width: 30px;
  font-size: 20px;
  height: 30px;
  color: #ffffff;
  line-height: 30px;
  text-align: center;
  background: #FF7129;
}
</style>
  